import * as React from "react"
import { ThemeContext } from "styled-components/macro"
import { CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node } from "../../../../__generated__/CentralCurrentUsersEntriesQuery"
import {
  CentralGameInstancesQuery_allSegments,
  CentralGameInstancesQuery_gameInstances_edges_node,
} from "../../../../__generated__/CentralGameInstancesQuery"
import { ENUM_CHALLENGE, ENUM_MANAGER, ENUM_PARLAY, ENUM_POST } from "../../../../common/enums"
import { GameSportType } from "../../../../common/game-enums"
import buildMappingFor, { buildTtag } from "../../../../common/game-text"
import { emptyArray } from "../../../../common/misc-utils"
import { colorFor, getThemeValues } from "../../../../common/theme-helpers"
import themes from "../../../../common/themes"
import { withoutDomain, findOgBy } from "../../../../common/url-utils"
import { IGameRootRouteMatch } from "../../../../routes.d"
import { useLocalKey } from "../../../Base/LocalKey"
import AnalyticScreen from "../../../components/AnalyticsScreen"
import { ButtonBase, ButtonBaseAlt, ButtonWithAfterContentInteraction } from "../../../components/Button"
import CustomCopy from "../../../components/CustomCopy"
import SplashSoloSvgs from "../../../components/icons/MillionDollarText"
import OrSvg from "../../../components/icons/Or"
import TriangleSvg from "../../../components/icons/Triangle"
import Link from "../../../components/Link"
import LoadingView from "../../../components/LoadingView"
import { Logo } from "../../../components/Logos"
import OpacityLink from "../../../components/OpacityLink"
import SeasonDisclaimer from "../../../components/SeasonDisclaimer"
import ToggleNexusTheme from "../../../components/ToggleNexusTheme"
import { entryHasManagerRole } from "../../../hooks/usePoolData"
import Analytics from "../../../utils/analytics"
import { getAuthSearch } from "../../../utils/url-utils"
import { IAuthGateView } from "../AuthGateTypings.d"
import { LobbyCtaCard, LobbyEntry } from "../components/Lobby"
import FixedBgScreen from "../styles/FixedBgScreen.styles"
import {
  NflParlayPromo,
  NflParlayPromoPoint,
  ParlayPromoGlobalStyles,
  PromoPage,
  ReactivateEntriesContainer,
  TextContainer,
} from "../styles/SplashViews.styles"
import { lowerSectionSvgColor } from "../../../../common/splash-themed-styles"
import DoubleClickWrapper from "../../../components/DoubleClickWrapper"
import PlayForCashSVG from "../styles/PlayForCashSVG"
import StartAPoolSVG from "../styles/StartAPoolSVG"
const ReactivatePoolModal = React.lazy(() => import("../../Layout/components/ReactivatePoolModal"))

interface IReactivateEntries {
  gameInstances: CentralGameInstancesQuery_gameInstances_edges_node[]
  allCurrentEntries: CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node[]
  allSegments: CentralGameInstancesQuery_allSegments[] | null
}

interface IOnboardingViewProps extends IGameRootRouteMatch, IAuthGateView, IReactivateEntries {
  needsAuthGate?: boolean
  isSingleGameInstanceArea: boolean
}

function ThemedBtn(props: any) {
  const themeContext = React.useContext(ThemeContext)
  const theme = themes[themeContext.id] || themes.default
  const { isDarkSecondary } = theme
  if (isDarkSecondary) {
    return <ButtonBaseAlt {...props} />
  }
  return <ButtonBase {...props} />
}

const modalKey = "rolloverModal"

export function getReactivatablePools(props: IReactivateEntries) {
  const { allCurrentEntries, gameInstances, allSegments } = props
  const _allSegments = allSegments || emptyArray
  const maxOrder = Math.min(...gameInstances.map((gi) => gi.currentPeriod?.order || 999), 9999)
  const entriesInGameInstances = allCurrentEntries.filter((entry) => !!gameInstances.find((gi) => gi.uid === entry.gameInstanceUid))
  const entriesInSeasons = entriesInGameInstances.filter(
    (entry) => !!gameInstances.find((gi) => gi.currentPeriod?.segment?.season.id === entry.pool.season.id),
  )
  // dont show if we are past week 2...
  // ...or if season has ended...
  const inSeasonGI = gameInstances.find(
    (gi) => allSegments && allSegments.find((seg) => seg.season.gameInstance.id === gi.id && seg.season.isCurrent && !seg.hasEnded),
  )
  const dontShowRolloverPools = !inSeasonGI || maxOrder > 2
  const allEntriesWithValidPools = allCurrentEntries.filter((entry) => entry.pool.entriesCount > 1)
  const allNonChallengeValidEntries = allEntriesWithValidPools.filter((entry) => !entry.pool.season.challengePoolSlug)
  // const entriesNotInGameInstances = allNonChallengeEntries.filter((entry) => (
  //   !entriesInGameInstances.includes(entry)
  // ));
  const entriesInGameInstancesNotFromSeason = entriesInGameInstances.filter((entry) => !entriesInSeasons.includes(entry))
  const entriesFromFormerYear = entriesInGameInstancesNotFromSeason.filter(
    (entry) =>
      !!gameInstances.find((gi) => {
        const _seg = _allSegments.find((seg) => seg.id === gi.currentPeriod?.segment.id)
        return _seg?.season.season === entry.pool.season.season
      }),
  )
  const entriesFromOtherSeasonType = entriesInGameInstancesNotFromSeason.filter((entry) => !entriesFromFormerYear.includes(entry))
  const poolIdsInEntries = allCurrentEntries.filter((e) => e.reinvitedPassword || e.currentSeasonEntryId).map((e) => e.pool.id)

  let entriesToReactivate: CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node[] = []
  if (!dontShowRolloverPools) {
    if (entriesFromFormerYear.some(entryHasManagerRole)) {
      entriesToReactivate = entriesFromFormerYear.filter(
        (e) => allNonChallengeValidEntries.includes(e) && (!e.pool.currentSeasonPoolId || poolIdsInEntries.includes(e.pool.currentSeasonPoolId)),
      )
    } else {
      entriesToReactivate = entriesFromFormerYear.filter(
        (e) => allNonChallengeValidEntries.includes(e) && e.pool.currentSeasonPoolId && !e.currentSeasonEntryId,
      )
    }
  }
  let entriesToRollover: CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node[] = []
  if (!dontShowRolloverPools) {
    if (entriesFromOtherSeasonType.some(entryHasManagerRole)) {
      entriesToRollover = entriesFromOtherSeasonType.filter(
        (e) => allNonChallengeValidEntries.includes(e) && (!e.pool.currentSeasonPoolId || poolIdsInEntries.includes(e.pool.currentSeasonPoolId)),
      )
    } else {
      entriesToRollover = entriesFromOtherSeasonType.filter((e) => allNonChallengeValidEntries.includes(e) && e.pool.currentSeasonPoolId)
    }
  }
  // console.log("entriesInGameInstances")
  // console.dir(entriesInGameInstances)
  // console.log("allCurrentEntries")
  // console.dir(inSeasonGI)
  return {
    entriesToReactivate,
    entriesToRollover,
    entriesInSeasons,
    entriesInGameInstances,
  }
}

export function ReactivateEntries(props: IReactivateEntries) {
  const { allCurrentEntries, allSegments } = props
  const reactivatablePools = getReactivatablePools(props)
  const modalProps = useLocalKey("modal")

  const { entriesToReactivate, entriesToRollover } = reactivatablePools
  if (!reactivatablePools.entriesToReactivate.length && !reactivatablePools.entriesToRollover.length) {
    return null
  }
  const modalParts = (modalProps.value || "").split("|")
  const modalIsOpen = modalParts.length > 2 && modalParts[0] === modalKey
  const entryIdToOpen = (modalParts.length > 1 && modalParts[1]) || ""
  const entryInModal = allCurrentEntries.find((e) => e.id === entryIdToOpen)
  const hasManagerRole = entryHasManagerRole(entryInModal)
  const isReinvited = !!entryInModal?.reinvitedPassword

  return (
    <ReactivateEntriesContainer className="reactivate-sections" id="reactivate">
      {(entriesToRollover.length && (
        <div className="reactivate-section">
          <div className="reactivate-section__title">Bring your pool to bowl season</div>
          <div className="reactivate-section__items">
            {entriesToRollover.map((entry) => {
              return (
                <ThemedBtn
                  key={entry.id}
                  className="reactivate-section__item"
                  as={Link}
                  onClick={() => modalProps.update(`${modalKey}|${entry.id}|open`)}
                  to={{ search: `?modal=${modalKey}|${entry.id}|open` }}
                >
                  {entry.pool.name}
                </ThemedBtn>
              )
            })}
          </div>
        </div>
      )) ||
        null}
      {(entriesToReactivate.length && (
        <div className="reactivate-section">
          <div className="reactivate-section__title">Access Previous Year's Pool(s)</div>
          <div className="reactivate-section__items">
            {entriesToReactivate.map((entry) => {
              return (
                <ThemedBtn
                  key={entry.id}
                  className="reactivate-section__item"
                  as={Link}
                  onClick={() => modalProps.update(`${modalKey}|${entry.id}|open`)}
                  to={{ search: `?modal=${modalKey}|${entry.id}|open` }}
                >
                  {entry.pool.name}
                </ThemedBtn>
              )
            })}
          </div>
        </div>
      )) ||
        null}

      <React.Suspense fallback={<LoadingView />}>
        <ReactivatePoolModal
          poolDetail={entryInModal! && entryInModal!.pool}
          allSegments={allSegments}
          isReinvited={isReinvited}
          hasManagerRole={hasManagerRole}
          isOpen={modalIsOpen}
          onClose={() => modalProps.update(`${modalKey}|${entryIdToOpen}`)}
          afterClose={() => modalProps.update(null)}
        />
      </React.Suspense>
    </ReactivateEntriesContainer>
  )
}

// const Sponsor = ({sponsorImgSrc}) => {
//   if (sponsorImgSrc) {
//     return (
//       <div className="splash-sponsors">
//         <span className="splash-sponsors-title">Presented by</span>
//         <img src={sponsorImgSrc} loading="lazy" className="splash-sponsor" />
//       </div>
//     );
//   }
//   return null;
// };

export const OnboardingView = ({
  sportType,
  gameType,
  subsection,
  gameInstances,
  returnUrl,
  masterProductId,
  isCbsAppWebview,
  needsAuthGate,
  allCurrentEntries,
  allSegments,
  isSingleGameInstanceArea,
  gameInstanceUid,
}: IOnboardingViewProps) => {
  // console.log(`OnboardingView: ${isSingleGameInstanceArea}`);
  // console.dir(allCurrentEntries);
  // console.dir(allSegments);
  // console.dir(gameInstances);
  const [challengeSelected, changeOption] = React.useState(true)
  const themeContext = React.useContext(ThemeContext)
  const managerGI = gameInstances.find((gi) => gi.poolType === ENUM_MANAGER && !!gi.currentPeriod)
  const challengeGI = gameInstances.find((gi) => gi.poolType === ENUM_CHALLENGE && !!gi.currentPeriod)
  const challengePeriod = challengeGI?.currentPeriod
  const managerPeriod = managerGI?.currentPeriod
  const period = challengePeriod || managerPeriod
  const _allSegments = allSegments || emptyArray
  const challengeSegment = _allSegments.find((seg) => challengePeriod?.segment.id === seg.id)
  const managerSegment = _allSegments.find((seg) => managerPeriod?.segment.id === seg.id)
  const segment = challengeSegment || managerSegment
  const season = segment?.season
  if (!themeContext || !period || !segment || !season) {
    return null
  }
  const isPostSeason = season.season === ENUM_POST
  const productAbbrev = season.productAbbrev
  const reactivationProps = {
    allCurrentEntries,
    gameInstances,
    allSegments,
  }
  const reactivatablePools = getReactivatablePools(reactivationProps)
  // console.dir(reactivatablePools)
  const { entriesToReactivate, entriesToRollover, entriesInSeasons } = reactivatablePools
  const challengeEntryInSeason = entriesInSeasons.find((e) => challengeGI && e.gameInstanceUid === challengeGI.uid)
  const hasChallengeEntry = !!challengeEntryInSeason
  const theme = getThemeValues(themeContext, true)
  const { isDarkSecondary, primary } = theme
  const isNfl = segment.sportType === GameSportType.values.NFL.value
  const isParlay = !!segment && segment.gameType === ENUM_PARLAY
  const poolPrefix = (isNfl && `playoff`) || "bowl"
  const reactivatePromoText =
    // entries from former year
    (entriesToReactivate.length && `Access your previous pool(s):`) ||
    // entriesFromOtherSeasonType
    (entriesToRollover.length && `Bring your pool to ${poolPrefix} season:`) ||
    null
  // console.dir(season)
  const gis = [challengeGI, null, managerGI]

  const runningSeasonGi = gis.find((gi) => gi && _allSegments.find((seg) => seg.season.gameInstance.id === gi.id && seg.hasStarted && !seg.hasEnded))
  const isPreStart = !runningSeasonGi && !!segment && !segment.hasStarted
  const soloUrl = challengeSegment ? withoutDomain(challengeSegment.landingUrl) + (runningSeasonGi ? "" : "/standings") : undefined
  const managerCreatePoolUrl = managerSegment ? withoutDomain(managerSegment.landingUrl) : undefined
  // const gisWONulls = gis.filter(filterNulls);
  const isChallengeOnly = isSingleGameInstanceArea && gis[0]!.poolType === ENUM_CHALLENGE
  const search = needsAuthGate && getAuthSearch(returnUrl, productAbbrev, masterProductId, isCbsAppWebview)
  const primaryGameInstance = runningSeasonGi || season.gameInstance || challengeGI || managerGI
  const og = findOgBy(primaryGameInstance.uid, season.season)
  if (!og) {
    return null
  }
  const ttagSegment = "splash"
  const primaryGameText = buildMappingFor(primaryGameInstance.uid, season.season, season.year, productAbbrev, ttagSegment, season, segment)
  const {
    splashPageIsCustomVersion,
    splashPageMidLeftDecoratorImageUrl,
    splashPageBottomRightDecoratorImageUrl,
    splashPagePhonePromoImageUrl,
    // splashPageBgImageRule,
    // splashPageBgColor,
    // splashPageBgColorStart,
    // splashPageBgColorStop,
    // splashPageBgSize,
    // splashPageBgBlendMode,
    // splashPageBgPosition,
    splashPageBgIsFixed,
    sponsorLogoUrl,
    sponsorLogoDoubleClickSuffix,
    sponsorKey,
  } = primaryGameText
  const usesCustomSoloStyle = !!splashPageIsCustomVersion
  const usesCustomVideo = season.year < 2020 && isParlay
  const PageContainer = (isChallengeOnly && NflParlayPromo) || PromoPage
  const textColor = colorFor({ theme: themeContext })
  const svgGroupColor = lowerSectionSvgColor({ theme: themeContext })
  const moreGamesUpselLink =
    (!isCbsAppWebview &&
      `https://www.cbssports.com/fantasy/games/football/?ttag=${buildTtag(season.productAbbrev, season.year, "FF", ttagSegment)}`) ||
    null
  // const themeId = constants.layoutIds
  const classNames = [`onboarding-view__is-challenge-only--${isChallengeOnly}`, `onboarding-view__is-custom-solo--${usesCustomSoloStyle}`]
  return (
    <ToggleNexusTheme isNexusStyles={false}>
      <PageContainer className={classNames.join(" ")}>
        <AnalyticScreen feature={`splash ${productAbbrev}`} subfeature={`home`} title={`Splash Page - Home`} />
        <FixedBgScreen className="fixed-bg" isModal={isChallengeOnly && !splashPageBgIsFixed} />
        {(isChallengeOnly && challengeGI && (
          <>
            <ParlayPromoGlobalStyles />
            {usesCustomSoloStyle && <div className="lower-section-bg-1" />}
            <div className={`gutters flex-row ${isNfl ? "centered" : ""}`}>
              <div className="left-col">
                <Logo className="logo" gameInstanceUid={challengeGI.uid} season={season} />
                {/* NOTE qac: wh doesnt want sponsored by to show... instead in the logo */}
                {(sponsorLogoUrl && sponsorKey !== "williamhill" && (
                  <DoubleClickWrapper className="sponsor-lockup" suffix={sponsorLogoDoubleClickSuffix}>
                    <p>Presented By</p>
                    <img src={sponsorLogoUrl} alt="Sponsor Logo" loading="lazy" />
                  </DoubleClickWrapper>
                )) ||
                  null}
                <CustomCopy as="p" className="h4" code="splashPageChallOnlyTitle" />

                {usesCustomSoloStyle && (
                  <SplashSoloSvgs.MillionDollarText className={`mill-svg is-parlay--${isParlay}`} isMill={isParlay} isFiveK={isPostSeason} />
                )}
                <CustomCopy as="p" className="h5" code="splashPageChallOnlySubTitle" />
                {splashPageMidLeftDecoratorImageUrl && (
                  <img
                    className="onboarding-view__decorator onboarding-view__decorator--mid-left"
                    src={splashPageMidLeftDecoratorImageUrl}
                    alt="decorator"
                    loading="lazy"
                  />
                )}
                {splashPageBottomRightDecoratorImageUrl && (
                  <img
                    className="onboarding-view__decorator onboarding-view__decorator--bottom-right"
                    src={splashPageBottomRightDecoratorImageUrl}
                    alt="decorator"
                    loading="lazy"
                  />
                )}
                <ButtonWithAfterContentInteraction
                  as={Link}
                  className={usesCustomSoloStyle ? "buttony" : "pill-btn"}
                  to={(search ? { pathname: "/auth/login", search } : soloUrl) || "#"}
                  onClick={() => Analytics.trackInteraction(`splash ${season.productAbbrev} - solo`)}
                >
                  {runningSeasonGi || isPreStart ? (usesCustomSoloStyle ? `Play For Free` : `Play Now`) : `View Results`}
                </ButtonWithAfterContentInteraction>
              </div>
              {!isNfl && (
                <div className="right-col">
                  {(usesCustomVideo && (
                    <video
                      className="media-el video desktop"
                      controls={false}
                      autoPlay={true}
                      loop={true}
                      muted={true}
                      playsInline={true}
                      loading="lazy"
                    >
                      <source src="/static/game-assets/nfl-parlay/splash-video.webm" type="video/webm" />
                      <source src="/static/game-assets/nfl-parlay/splash-video.mp4" type="video/mp4" />
                    </video>
                  )) ||
                    (splashPagePhonePromoImageUrl && <img className="media-el img" alt="Phone example" src={splashPagePhonePromoImageUrl} />) || (
                      <p>No splashPagePhonePromoImageUrl for game</p>
                    )}
                  {/*<img className="media-el img" src={nflParlayPromoPhone} alt="Phone example" loading="lazy" />*/}
                  {/*<img className="media-el img" src="/static/game-assets/nfl-parlay/splash-video.gif" alt="Phone example" loading="lazy" />*/}

                  {/*<video className="media-el video mobile" controls={false} autoPlay={true} loop={true} muted={true} playsInline={true} loading="lazy">
                <source src="/static/game-assets/nfl-parlay/splash-video.mp4" type="video/mp4" />
              </video>*/}
                </div>
              )}
            </div>
            {usesCustomSoloStyle && (
              <>
                <CustomCopy className="gutters h3" code="splashPageChallOnlyHeading" />
                <SplashSoloSvgs.Baseline className="gutters baseline" />
                <div className="gutters lower-section">
                  <SplashSoloSvgs.MidBg className="lower-section-bg-2" />
                  <SplashSoloSvgs.MidBgMobile className="lower-section-bg-2--mobile" />
                  <NflParlayPromoPoint>
                    <SplashSoloSvgs.GroupItemOne topColor={textColor} mainColor={svgGroupColor} />
                    <div>
                      <CustomCopy as="header" code="splashPageChallOnlyGroup1Header" />
                      <CustomCopy as="p" code="splashPageChallOnlyGroup1P" />
                    </div>
                  </NflParlayPromoPoint>
                  <NflParlayPromoPoint className="right">
                    <SplashSoloSvgs.GroupItemTwo topColor={textColor} mainColor={svgGroupColor} />
                    <div>
                      <CustomCopy as="header" code="splashPageChallOnlyGroup2Header" />
                      <CustomCopy as="p" code="splashPageChallOnlyGroup2P" />
                    </div>
                  </NflParlayPromoPoint>
                  <NflParlayPromoPoint>
                    <SplashSoloSvgs.GroupItemThree topColor={textColor} mainColor={svgGroupColor} />
                    <div>
                      <CustomCopy as="header" code="splashPageChallOnlyGroup3Header" />
                      <CustomCopy as="p" code="splashPageChallOnlyGroup3P" />
                    </div>
                  </NflParlayPromoPoint>
                </div>
                {!moreGamesUpselLink ? (
                  <CustomCopy className="footer-h" code="splashPageChallOnlySecondCta" />
                ) : (
                  <CustomCopy className="footer-h" code="splashPageChallOnlyUpsellCta" />
                )}
                {!moreGamesUpselLink ? (
                  <Link
                    className="gutters parlay-see-more buttony"
                    to={(search ? { pathname: "/auth/login", search } : soloUrl) || "#"}
                    onClick={() => Analytics.trackInteraction(`splash ${season.productAbbrev} - solo`)}
                  >
                    {runningSeasonGi ? `Play Now` : `View Results`}
                  </Link>
                ) : (
                  <Link
                    className="gutters parlay-see-more buttony"
                    to={moreGamesUpselLink}
                    onClick={() => Analytics.trackInteraction(`splash ${season.productAbbrev} - see more`)}
                  >
                    See More Games
                  </Link>
                )}
              </>
            )}
            <SeasonDisclaimer className="disclaimer" season={season} />
          </>
        )) || (
          <>
            <div className="promo-container">
              <div className={`promo-left has-sponsor--${!!sponsorLogoUrl}`}>
                <Logo gameInstanceUid={challengeGI!.uid} season={season} />
                {(sponsorLogoUrl && (
                  <div className="sponsor-stacked-img">
                    <p>Presented By</p>
                    <img src={sponsorLogoUrl} alt="Sponsor Logo" loading="lazy" />
                  </div>
                )) ||
                  null}
              </div>
              {(reactivatePromoText && (
                <OpacityLink as="a" to="" className="reactivate-mobile-prompt" href="#reactivate">
                  <div className="reactivate-mobile-prompt__text">{reactivatePromoText}</div>
                  <TriangleSvg />
                </OpacityLink>
              )) ||
                null}
              {(isChallengeOnly && (
                <div className="promo-right promo-right--season-over-false">
                  <span>TODO</span>
                </div>
              )) ||
                (!hasChallengeEntry && runningSeasonGi && (
                  <div className="promo-right promo-right--season-over-false">
                    {gis.map((gi) => {
                      if (!gi) {
                        return <OrSvg key={"1"} className="orsvg" />
                      }
                      const isChallenge = gi === challengeGI
                      const isSelected = isChallenge ? challengeSelected : !challengeSelected
                      const gradientId = `obd--${gi.uid}`
                      const radioOutlineColor = isDarkSecondary ? "#fff" : "currentColor"
                      // const description = isNfl;
                      // tslint:disable-next-line
                      // const desc = isChallenge ? "Pick all 40 bowl games and compete against the CBS Sports community for the <strong$5,000 cash prize." : "Create a private pool where you can customize the rules, slate of games, against the spread or straight up scoring and confidence points."
                      return (
                        <button
                          key={gi.id}
                          className={`option is-selected--${isSelected}`}
                          type="button"
                          onClick={() => changeOption(gi === challengeGI ? true : false)}
                        >
                          <div className="cta">
                            <svg className="radio" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle className="radio__outer-c" fill="none" cx="11.5" cy="11.5" r="9.5" stroke={radioOutlineColor} strokeWidth="4" />
                              <circle className="radio__inner-c" cx="11.5" cy="11.5" r="6.192" fill={primary} />
                              <circle
                                className="radio__inner-c"
                                cx="11.5"
                                cy="11.5"
                                r="6.192"
                                fill={isDarkSecondary ? `url(#${gradientId})` : primary}
                              />
                              <circle className="radio__inner-c" cx="11.5" cy="11.5" r="6.192" fill="none" stroke={radioOutlineColor} />
                              <defs>
                                <linearGradient id={gradientId} x1="11.5" y1="5.308" x2="11.5" y2="17.692" gradientUnits="userSpaceOnUse">
                                  <stop stopColor="#fff" />
                                  <stop offset="1" stopColor="#fff" stopOpacity="0" />
                                </linearGradient>
                              </defs>
                            </svg>
                            {isChallenge ? (
                              <TextContainer>
                                <PlayForCashSVG isDarkSecondary={isDarkSecondary} gradientId={gradientId} />*
                              </TextContainer>
                            ) : (
                              <TextContainer>
                                <StartAPoolSVG isDarkSecondary={isDarkSecondary} gradientId={gradientId} />
                                **
                              </TextContainer>
                            )}
                          </div>
                          <CustomCopy className="desc" code="splashPageHtml" gameInstance={gi} />
                        </button>
                      )
                    })}
                    <ThemedBtn className="next-cta" as={Link} to={challengeSelected ? soloUrl : managerCreatePoolUrl}>
                      Next
                    </ThemedBtn>
                  </div>
                )) ||
                (!runningSeasonGi && !entriesInSeasons.length && (
                  <div className="promo-right promo-right--season-over-true">
                    <h3 className="season-over-h3">{`The ${(season && season.year) || ""} season ${isPreStart ? "coming soon!" : "is over."}`}</h3>
                    {(challengeGI && soloUrl && !isPreStart && (
                      <ThemedBtn className="next-cta" as={Link} to={soloUrl}>
                        View Results
                      </ThemedBtn>
                    )) ||
                      null}
                  </div>
                ))}
            </div>
            {entriesInSeasons.length ? (
              <>
                <div className="bracket-splash-description entries-title">Your Entries</div>
                <div className="entries">
                  {entriesInSeasons.map((entry) => {
                    return <LobbyEntry key={entry.id} entry={entry} isChallenge={false} />
                  })}
                  {(hasChallengeEntry && runningSeasonGi && <LobbyCtaCard createPoolPath={managerCreatePoolUrl} challengePoolPath={""} />) || null}
                </div>
              </>
            ) : null}
            <ReactivateEntries {...reactivationProps} />
            {gis.map((gi) => {
              if (gi) {
                const isChallenge = gi === challengeGI
                const seg = isChallenge ? challengeSegment : managerSegment
                const ss = seg?.season

                return <SeasonDisclaimer season={ss} identifier={isChallenge ? "*  " : "**  "} id={ss?.id} />
              }
              return null
            })}
          </>
        )}
      </PageContainer>
    </ToggleNexusTheme>
  )
}
export default OnboardingView
