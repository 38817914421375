import React from "react"
import { useLocation } from "react-router-dom"
import styled from "styled-components/macro"

const Link = styled.a`
  & {
    display: block;
    color: inherit;
  }
`
const ImpressionImg = styled.img`
  & {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    width: 1px;
    height: 1px;
  }
`

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
  suffix?: string
}

const DoubleClickWrapper: React.FC<IProps> = ({ suffix, ref, ...props }) => {
  const location = useLocation()
  if (suffix) {
    const ord = location.key || "unknown"
    const fixedSuffix = suffix.split("?")[0]
    const impressionUrl = `https://ad.doubleclick.net/ddm/trackimp/${fixedSuffix};ord=${ord}`
    const href = `https://ad.doubleclick.net/ddm/trackclk/${fixedSuffix}`
    return (
      <>
        <ImpressionImg src={impressionUrl} loading="lazy" alt="advertisement" />
        <Link href={href} target="_blank" rel="noopener nofollow" {...props} />
      </>
    )
  } else {
    return <div {...props} />
  }
}

export default React.memo(DoubleClickWrapper)
