import styled from "styled-components/macro"
import { ButtonBase } from "../../../components/Button"
import { FlexCol, FlexColGrow, FlexCols, FlexRow } from "../../../components/FlexComponents"
import { fontWeight, palette, pxToRem } from "../../../utils/style-utils"

export const LobbyContainer = styled.div`
  margin: ${pxToRem(16)} 0 0;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
`
export const LobbyCTA = styled(ButtonBase)`
  margin: 0 ${pxToRem(35)} ${pxToRem(32)};
  padding: ${pxToRem(16)} ${pxToRem(8)};
  min-height: auto;
  max-width: ${pxToRem(280)};
`

export const CardBase = styled(FlexCols)`
  background-color: #fff;
  border: ${pxToRem(1)} solid ${palette.gray3};
  border-radius: ${pxToRem(2)};
  width: 100%;
  position: relative;
  max-width: ${pxToRem(350)};
  min-height: ${pxToRem(100)};
  justify-content: space-between;
  & a {
    pointer-events: auto !important;
  }
  & .card-move {
    transition: all 0.2s ease-out;
  }
  @media (hover: hover) {
    :hover:not(:active) .card-move {
      transform: translateX(${pxToRem(8)});
      opacity: 0.9;
    }
  }
  :active .card-move {
    transform: translateX(${pxToRem(6)});
    opacity: 0.8;
  }
`
export const LobbyCard = styled(CardBase)`
  width: ${pxToRem(350)};
  transition: all 0.2s ease-in-out;
  will-change: background-color;
  color: #000 !important;
  &:not(.is-preseason) {
    min-height: ${pxToRem(180)};
  }
  @media (hover: hover) {
    :hover:not(:active) {
      background-color: rgba(255, 255, 255, 0.95);
    }
  }
  :active {
    background-color: rgba(255, 255, 255, 0.9);
  }
`

export const LobbyRow = styled(FlexRow)`
  width: 100%;
  padding: ${pxToRem(8)} ${pxToRem(16)};
`
export const LobbyRowSub = styled(FlexColGrow)`
  padding: 0 0 ${pxToRem(2)} ${pxToRem(8)};
  align-items: flex-start;
  width: 100%;
  white-space: normal;
  text-align: left;
`
export const LobbyRowMid = styled(FlexColGrow)`
  width: 100%;
  padding: 0 ${pxToRem(16)};
`
export const LobbyEntryName = styled(LobbyRowSub)`
  font-size: ${pxToRem(16)};
  font-weight: ${fontWeight.semiBold};
  text-transform: none;
`
export const LobbyEntryNameSub = styled(LobbyRowSub)`
  font-size: ${pxToRem(12)};
  text-transform: none;
`
export const WarningCircle = styled.div`
  width: ${pxToRem(12)};
  height: ${pxToRem(12)};
  margin-right: ${pxToRem(4)};
  border-radius: 100%;
  background-color: red;
`
export const SvgAction = styled(FlexCol)`
  min-width: ${pxToRem(48)};
  max-width: ${pxToRem(48)};
  min-height: ${pxToRem(48)};
  max-height: ${pxToRem(48)};
  padding: ${pxToRem(10)};
`
export const SvgActionAbs = styled(SvgAction)`
  position: absolute;
  margin: ${pxToRem(6)};
  color: ${palette.buttonOrange1};
  transition: opacity 0.2s ease-in-out;
  will-change: opacity;
  @media (hover: hover) {
    :hover:not(:active) {
      opacity: 0.8;
    }
  }
  :active {
    opacity: 0.6;
  }
  & svg {
    width: 100%;
    height: 100%;
  }
`
export const SvgActionAbsTopRight = styled(SvgActionAbs)`
  top: 0;
  right: 0;
`
export const SvgActionAbsBotRight = styled(SvgActionAbs)`
  bottom: 0;
  right: 0;
`
export const LobbyDL = styled(FlexColGrow)`
  flex-flow: column;
  padding-right: ${pxToRem(8)};
`
export const LobbyDT = styled(LobbyRowSub)`
  color: rgba(0, 0, 0, 0.8);
  letter-spacing: ${pxToRem(0.7)};
  font-size: ${pxToRem(12)};
  text-transform: uppercase;
  padding-bottom: ${pxToRem(6)};
`
export const LobbyDD = styled(LobbyRowSub)`
  color: #236bd7;
  letter-spacing: ${pxToRem(1.2)};
  text-transform: uppercase;
  font-size: ${pxToRem(18)};
  font-weight: ${fontWeight.bold};
  align-items: flex-start;
  width: 100%;
  white-space: normal;
`
export const LobbyDLSep = styled.div`
  border-right: ${pxToRem(1)} solid #cccccc66;
  flex: 0 1 auto;
  width: ${pxToRem(1)};
  height: ${pxToRem(36)};
`
export const LobbyPts = styled.div`
  color: #236bd7;
  letter-spacing: ${pxToRem(1.5)};
  font-size: ${pxToRem(28)};
  font-weight: ${fontWeight.extraBold};
`
export const LobbyCtaDescr = styled(FlexRow)`
  font-size: ${pxToRem(18)};
  text-transform: uppercase;
  padding: ${pxToRem(8)} 0;
  font-weight: ${fontWeight.semiBold};
`
export const LobbyTitleCenter = styled(FlexCols)`
  overflow: hidden;
`
export const LobbyCardContainer = styled.div`
  margin: ${pxToRem(8)} ${pxToRem(8)};
  position: relative;
  color: ${palette.default};
`
