import React from "react"
import AnalyticScreen from "../../../components/AnalyticsScreen"
import { AvatarBase } from "../../../components/Avatar"
import { FlexColGrow } from "../../../components/FlexComponents"
import Link from "../../../components/Link"
import { toRank } from "../../../utils/misc-utils"
import {
  CardBase,
  LobbyCard,
  LobbyCardContainer,
  LobbyContainer,
  LobbyCTA,
  LobbyCtaDescr,
  LobbyDD,
  LobbyDL,
  LobbyDLSep,
  LobbyDT,
  LobbyEntryName,
  LobbyEntryNameSub,
  LobbyPts,
  LobbyRow,
  LobbyRowMid,
  LobbyTitleCenter,
  WarningCircle,
} from "../styles/Lobby.styles"

export const LobbyEntry = ({ entry, isChallenge }) => {
  const poolRoot = entry.pool.url

  const periodPoint = entry.periodPoint || {}
  const isPreseason = true
  return (
    <LobbyCardContainer>
      <LobbyCard as={Link} to={poolRoot} className={isPreseason ? `is-preseason` : ""}>
        <LobbyRow>
          <AvatarBase size={40} as="img" src={(entry && entry.avatarUrl) || `//placehold.it/150x150`} />
          <LobbyTitleCenter>
            <LobbyEntryName className="card-move">{(isChallenge && entry.name) || entry.pool.name || "nopool"}</LobbyEntryName>
            <LobbyEntryNameSub>{isChallenge ? entry.pool.name : entry.name}</LobbyEntryNameSub>
            {(!isPreseason && entry.needsToMakePicks && (
              <LobbyEntryNameSub>
                <WarningCircle />
                Need to make picks
              </LobbyEntryNameSub>
            )) ||
              (!isPreseason && <LobbyEntryNameSub>All picks made!</LobbyEntryNameSub>) ||
              null}
          </LobbyTitleCenter>
          {/*<SvgAction />*/}
        </LobbyRow>
        {(!isPreseason && (
          <LobbyRowMid>
            <LobbyPts>
              {periodPoint.fantasyPoints || 0} {periodPoint.fantasyPoints === 1 ? "Pt" : "Pts"}
            </LobbyPts>
            <LobbyDL>
              <LobbyDD>
                {periodPoint.poolRank || "--"}
                <sup>{toRank(periodPoint.poolRank, true)}</sup>
              </LobbyDD>
              <LobbyDT>This week</LobbyDT>
            </LobbyDL>
          </LobbyRowMid>
        )) ||
          null}
        <LobbyRow>
          <FlexColGrow>
            <LobbyDL>
              <LobbyDT>{false ? `Season Pts` : `Overall Pts`}</LobbyDT>
              <LobbyDD>{entry.fantasyPoints || 0}</LobbyDD>
            </LobbyDL>
            <LobbyDLSep />
            <LobbyDL>
              <LobbyDT>{false ? `Season Rank` : `Overall Rank`}</LobbyDT>
              <LobbyDD>{toRank(entry.poolRank) || "--"}</LobbyDD>
            </LobbyDL>
          </FlexColGrow>
          {/*<SvgAction />*/}
        </LobbyRow>
      </LobbyCard>
      {/*<SvgActionAbsTopRight as={Link} to={`${poolRoot}/settings`}><SettingsIcon /></SvgActionAbsTopRight>
    <SvgActionAbsBotRight as={Link} to={`${poolRoot}/invite-center`}><PersonAddIcon /></SvgActionAbsBotRight>*/}
      {/*<ButtonSmallBase as="button" style={stylez}>Reactivate</ButtonSmallBase>*/}
    </LobbyCardContainer>
  )
}
// const stylez = {
//   position: "absolute",
//   right: "1.25em",
//   top: "1.25em",

// };

export const LobbyCtaCard = ({ challengePoolPath = "", createPoolPath = "" }) => {
  return (
    <LobbyCardContainer>
      <CardBase>
        <LobbyCtaDescr>Want more action?</LobbyCtaDescr>
        <LobbyCTA as={Link} to={createPoolPath}>
          Create A Pool
        </LobbyCTA>
        {challengePoolPath && (
          <LobbyCTA as={Link} to={challengePoolPath}>
            Play for Cash
          </LobbyCTA>
        )}
      </CardBase>
    </LobbyCardContainer>
  )
}

const Lobby = ({ entries, challengeGI, managerGI, createPoolPath, challengePoolPath }) => {
  const challengeGIEntries = entries.filter((e) => e.gameInstanceUid === challengeGI.uid)
  const managerGIEntries = entries.filter((e) => e.gameInstanceUid !== challengeGI.uid)
  return (
    <LobbyContainer>
      <AnalyticScreen feature={`splash lobby`} subfeature={`lobby`} title={`Splash Page - Lobby`} />
      {managerGIEntries.map((entry) => {
        return <LobbyEntry key={entry.id} entry={entry} isChallenge={false} />
      })}
      {challengeGIEntries.map((entry) => (
        <LobbyEntry key={entry.id} entry={entry} isChallenge={true} />
      ))}
      {managerGI ? (
        <LobbyCtaCard createPoolPath={createPoolPath} challengePoolPath={(challengeGIEntries.length === 0 && challengePoolPath) || ""} />
      ) : null}
    </LobbyContainer>
  )
}

export default Lobby
