import * as React from "react"

function OrIcon(props) {
  return (
    <svg viewBox="0 0 99 63" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      {/* tslint:disable-next-line */}
      <path
        fill="none"
        d="M48.907 32.043c0 16.914-3.16 20.25-18.725 20.25-15.723 0-18.804-3.336-18.804-20.25s3.081-20.25 18.804-20.25c15.644 0 18.726 3.259 18.726 20.25zm-25.599 0c0 9.698.711 10.784 6.874 10.784 6.084 0 6.716-1.086 6.716-10.784 0-9.62-.632-10.707-6.716-10.707-6.084-.077-6.874 1.009-6.874 10.707zM86.28 12.569v10.397h-3.635c-4.978 0-6.637.232-9.718 1.551v18.155l4.424.388v8.535h-21.49V43.06l4.424-.388V21.337l-4.425-.31V12.49h16.988v5.897c3.476-5.043 5.451-6.285 10.27-6.285 1.107.078 2.371.233 3.161.466z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  )
}

export default OrIcon
